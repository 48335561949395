
import {WOW} from '../../../node_modules/wowjs/dist/wow';
// import './jquery.nicescroll.min'


if (window.localStorage) {
  console.log('浏览器支持localStorage');
  // 默认轮胎角度显示30°
  window.localStorage.getItem('angle') ? '' : window.localStorage.setItem('angle', '1'); //轮胎角度，0:0°   1:30°   2:90°

  var angle = window.localStorage.getItem('angle')
} else {
  var angle = '1';
  alert('该浏览器不支持localStorage')
}



//产品默认角度
if ($('.angle-choose').length > 0){
  $('.angle-choose .item').eq(angle).addClass('active').siblings().removeClass('active')
}
if ($('.pro-list').length > 0) {
  $('.pro-list ul li').each(function(i,val){
    $(val).find('.item-img img').eq(angle).fadeIn(200).siblings().fadeOut(200)
  })
}

//点击选择角度
$('.angle-choose .item').on('click', function () {
  let index = $(this).index()
  window.localStorage.setItem('angle', index)

  $(this).addClass('active').siblings().removeClass('active')

  if ($('.pro-list').length > 0) {
    $('.pro-list ul li').each(function (i, val) {
      $(val).find('.item-img img').eq(index).fadeIn(200).siblings().fadeOut(200)
    })
  }
})


// if (window.devicePixelRatio < 2) {
//   if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
//     $('body').niceScroll({
//       mousescrollstep: 40,
//       scrollspeed: 80,
//       zindex: 101,
//       cursorcolor: '#007b7b',
//       cursorborder: '1px solid #007b7b',
//       cursorwidth: '5px',
//       cursoropacitymax: 0.6,
//       smoothscroll: true,
//       iframeautoresizeL: true,
//     });
//   }
// }
// if (window.devicePixelRatio < 2) {
//   if (!(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent))) {
//     setTimeout(() => {
//       $('body').getNiceScroll().resize()
//     }, 500);
//   }
// }

// new WOW().init();


//页面滚动监听
// $(document).scroll(function () {
//   var scroH = $(document).scrollTop(); //滚动高度
//   var viewH = $(window).height(); //可见高度
//   var contentH = $(document).height(); //内容高度

//   if (scroH > 50) { //距离顶部大于140px时
//     $('.root-header').addClass('scroll-header')
//   } else {
//     $('.root-header').removeClass('scroll-header')
//   }
//   if (contentH - (scroH + viewH) <= 100) { //距离底部高度小于100px

//   }
//   if (contentH = (scroH + viewH)) { //滚动条滑到底部啦

//   }

// });

//页面滚动监听
// var isScroll = false;
// var beforeScroH = $(document).scrollTop();
// $(document).scroll(function () {
//   var scroH = $(document).scrollTop(); //滚动高度
//   var viewH = $(window).height(); //可见高度
//   var contentH = $(document).height(); //内容高度
//   var bannerTop = $('.index-news').offset().top;

//   //判断页面是上滚还是下滚
//   var delta = scroH - beforeScroH;
//   if (window.screen.width > 1200){
//     if (scroH == 0) {
//       isScroll = false;
//     }
//     if (delta > 0) {
//       if (scroH > 2 && scroH < bannerTop / 2 && isScroll == false) {
//         isScroll = true;
//         $('html ,body').animate({
//           scrollTop: $('.index-news').offset().top - 100
//         }, 300);
//         return false;
//       }
//     }
//     if (delta < 0) {
//       if (scroH < bannerTop && scroH > bannerTop / 2 && isScroll == true) {
//         isScroll = false;
//         $('html ,body').animate({
//           scrollTop: 0
//         }, 300);
//         return false;
//       }
//     }
//   }
//   beforeScroH = scroH;
// });